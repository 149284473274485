import { PropsWithChildren, ReactElement } from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import RBThemePRovider from 'react-bootstrap/ThemeProvider';
import { BrowserRouter } from 'react-router-dom';
// import TagManager from 'react-gtm-module';
import { setupIonicReact } from '@ionic/react';
import App from './components/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client';
import { client } from './graphql/client';
import { AppContextProvider } from './state';
import theme from './components/theme/theme';
import './index.css';

// const client = new ApolloClient({
//   uri: 'https://d3pu4cc3tvdlnhen6vkavikjne.appsync-api.eu-west-1.amazonaws.com/graphql',
//   cache: new InMemoryCache(),
// });

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
// import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import './components/theme/ionic-variables.css';
import Header from 'components/Header';
import Footer from 'components/Footer';
import CookieBanner from 'components/CookieBanner';
// import { cookieAnalyticsCookiesEnabled } from 'services/analytics';

// const { REACT_APP_GTM_API_KEY, REACT_APP_GTM_AUTH_TOKEN } = process.env;

export const event = 'event';
export const eventCategory = 'eventCategory';
export const eventAction = 'eventAction';
export const eventLabel = 'eventLabel';

setupIonicReact();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const AppProviders = ({ children }: PropsWithChildren): ReactElement => {
    //     const [isGTMInitialised, setIsGTMInitialised] = useState(false);

    // useEffect(() => {
    //     let t: ReturnType<typeof setTimeout>;
    //     const initGTM = () => {
    //         if (!isGTMInitialised) {
    //             if (cookieAnalyticsCookiesEnabled() && REACT_APP_GTM_API_KEY) {
    //                 TagManager.initialize({
    //                     gtmId: REACT_APP_GTM_API_KEY,
    //                     auth: REACT_APP_GTM_AUTH_TOKEN,
    //                     dataLayerName: 'dataLayer',
    //                     events: {
    //                         event,
    //                         eventCategory,
    //                         eventAction,
    //                         eventLabel,
    //                     },
    //                 });
    //                 setIsGTMInitialised(true);
    //             }
    //             t = setTimeout(() => {
    //                 if (!isGTMInitialised) {
    //                     initGTM();
    //                 }
    //             }, 5000);
    //         }
    //     };
    //     initGTM();
    //     return () => {
    //         if (t) {
    //             clearTimeout(t);
    //         }
    //     };
    // }, [isGTMInitialised]);

    return (
        <BrowserRouter>
            <ApolloProvider client={client}>
                <AppContextProvider>
                    <ThemeProvider theme={theme}>
                        <RBThemePRovider breakpoints={['lg', 'md', 'sm', 'xs']} minBreakpoint="xs">
                            {children}
                        </RBThemePRovider>
                    </ThemeProvider>
                </AppContextProvider>
            </ApolloProvider>
        </BrowserRouter>
    );
};

root.render(
    <AppProviders>
        <CookieBanner />
        <Header />
        <App />
        <Footer />
    </AppProviders>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
