import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { logAnalyticsEvent } from 'services/analytics';

import graphQLMutation from '../../services/graphql/mutation';
import {
    CREATE_PDF,
    RESET_CREATE_PDF,
    RESET_FORM,
    SET_RISK_RATING,
    SET_RISK_RATING_DESCRIPTION,
    useAppContext,
} from '../../state';

import riskRatings from './riskRatings';
import RiskRating1 from '../../assets/img/risk-rating-1.svg';
import RiskRating2 from '../../assets/img/risk-rating-2.svg';
import RiskRating3 from '../../assets/img/risk-rating-3.svg';
import RiskRating4 from '../../assets/img/risk-rating-4.svg';
import RiskRating5 from '../../assets/img/risk-rating-5.svg';
import debug from 'utils/debug';

interface RiskRating {
    hasQuestions?: boolean;
    rating?: string;
    riskRating?: string;
    description?: string;
    retakeQuestionnaire: Function;
    imageSrc?: string;
}

export const useRiskRating = (): RiskRating => {
    const { state, dispatch } = useAppContext();
    const [responsesSubmitted, setResponsesSubmitted] = useState(false);
    let rating, description, riskRating, imageSrc;

    const navigate = useNavigate();

    useEffect(() => {
        if (!state.questions.length) {
            navigate('/');
        }
        async function submitResponses() {
            try {
                const { rating: riskRating } = await graphQLMutation('calculateRisk', { responses: state.answers });
                logAnalyticsEvent({ action: `Results page`, label: `Attitude to risk - ${riskRating}` });
                dispatch({ type: SET_RISK_RATING, riskRating });

                const results = riskRatings[riskRating.toString()];
                const description = results.description;

                dispatch({ type: SET_RISK_RATING_DESCRIPTION, description });
            } catch {
                navigate('/error');
            }
        }
        if (
            !responsesSubmitted &&
            state.answers.length > 0 &&
            state.answers.length === state.questions.length &&
            !state.riskRating
        ) {
            logAnalyticsEvent({ action: `Results page`, label: `Display` });
            setResponsesSubmitted(true);
            submitResponses();
        }
    }, [state, dispatch, responsesSubmitted, navigate]);

    const retakeQuestionnaire = useCallback(() => {
        debug('calling retake questionnaire');
        logAnalyticsEvent({ action: `Results page`, label: `Re-take the test button` });
        dispatch({ type: RESET_FORM });
        dispatch({ type: RESET_CREATE_PDF });
        navigate('/questionnaire');
    }, [dispatch, navigate]);

    if (state.riskRating) {
        const results = riskRatings[state.riskRating.toString()];
        rating = results.rating;
        description = results.description;
        riskRating = state.riskRating;

        switch (riskRating) {
            case 1:
                imageSrc = RiskRating1;
                break;
            case 2:
                imageSrc = RiskRating2;
                break;
            case 3:
                imageSrc = RiskRating3;
                break;
            case 4:
                imageSrc = RiskRating4;
                break;
            case 5:
                imageSrc = RiskRating5;
                break;
            default:
                imageSrc = '';
        }
    }

    const hasQuestions = !!state.questions;

    return {
        imageSrc,
        hasQuestions,
        riskRating,
        rating,
        description,
        retakeQuestionnaire,
    };
};

export const useCreatePDF = () => {
    const { state, dispatch } = useAppContext();
    const [loading, setGotPDFURL] = useState(false);

    const { pdfUrl } = state;
    const createPDF = useCallback(async () => {
        logAnalyticsEvent({ action: `Results page`, label: `Download PDF button` });
        setGotPDFURL(true);

        //call GraphQL query to create pdf -
        const { url } = await graphQLMutation('generateRiskResultPDF', {
            input: {
                RiskRating: `${state.riskRating}`,
                RiskAnswers: state.answers,
            },
        });

        dispatch({ type: CREATE_PDF, url });
    }, [dispatch, state.riskRating, state.answers]);

    const getPDF = useCallback(async () => {
        const response = await axios.get(pdfUrl, { responseType: 'blob' });
        saveAs(response.data, 'risk-results.pdf');
    }, [pdfUrl]);

    useEffect(() => {
        if (pdfUrl) {
            setGotPDFURL(false);
            getPDF();
        }
    }, [getPDF, pdfUrl]);

    return {
        createPDF,
        loading,
    };
};
