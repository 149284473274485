/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const generateRiskResultPDF = /* GraphQL */ `
  mutation GenerateRiskResultPDF($input: RiskResultPDFInput) {
    generateRiskResultPDF(input: $input) {
      url
      __typename
    }
  }
`;
export const calculateRisk = /* GraphQL */ `
  mutation CalculateRisk($responses: [Response]) {
    calculateRisk(responses: $responses) {
      rating
      __typename
    }
  }
`;
