import { Col } from 'react-bootstrap';
import { Container, Row, ResultsWrapper, RiskRatingCard } from './styles';
import { useCreatePDF, useRiskRating } from './hooks';
import Button from '../ui/Button';
import { H2, P } from '../ui/Typography';
import LoadingState from './LoadingState';

import Card from 'components/ui/Card';
import { CardTitle, CardContent, CardFlex, CardContentContainer } from 'components/ui/Card/styles';
import { resultsContent } from 'data/content';
import { Link } from 'react-router-dom';

export interface Risk {
    level: string;
    description: string;
}

function Results() {
    const { imageSrc, rating, description, retakeQuestionnaire } = useRiskRating();
    const { createPDF, loading } = useCreatePDF();

    return (
        <Container className="my-3">
            {!rating && <LoadingState />}

            {rating && (
                <ResultsWrapper>
                    <RiskRatingCard>
                        <Row className="align-items-center">
                            <Col lg={6}>
                                <H2>
                                    {resultsContent.title} {rating}
                                </H2>
                                <P>{description}</P>

                                <Button secondary onClick={retakeQuestionnaire}>
                                    {resultsContent.buttonText}
                                </Button>
                            </Col>

                            <Col xs={{ order: 'first', span: 12 }} lg={{ order: 'last', span: 6 }}>
                                <img className="img-fluid" src={imageSrc} alt="risk rating info" />
                            </Col>
                        </Row>
                    </RiskRatingCard>

                    <H2 className="text-center">{resultsContent.heading}</H2>
                    <Row className="justify-content-center">
                        {resultsContent.cards.map((card, index) => (
                            <Col className="px-4 mb-4" key={index} lg={5}>
                                <Card key={index}>
                                    <CardFlex>
                                        <img src={card.icon} alt={card.iconAltText} />
                                        <CardContentContainer>
                                            <CardTitle className="card-title-height mb-2">{card.title}</CardTitle>
                                            <CardContent>
                                                <P>{card.content}</P>
                                            </CardContent>

                                            {card.id === 'downloadPDF' ? (
                                                <Button onClick={() => createPDF()}>
                                                    {!loading ? card.buttonText : 'Downloading PDF...'}
                                                </Button>
                                            ) : (
                                                <Link to={'/report-builder'}>
                                                    <Button>{card.buttonText}</Button>
                                                </Link>
                                            )}
                                        </CardContentContainer>
                                    </CardFlex>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </ResultsWrapper>
            )}
        </Container>
    );
}

export default Results;
