import IconDownload from '../assets/img/icon-download.svg';

export const questionnaireStart = {
    title: 'Check your attitude to risk',

    contentOne:
        'Check how much risk you might be comfortable taking with your investments with our risk profiler designed by EV, leading experts in the field of risk analysis.',

    contentTwo: `Choosing to invest, rather than saving alone, gives your money more chance to potentially grow. How much risk you take can affect your potential growth so it's important to understand how much you're willing and able to take.`,

    contentThree:
        'Our profiler helps you understand your attitude towards risk. It asks several key investment-related questions designed to assess your experience with different types of investments and your comfort level with taking risks.',

    contentFour:
        'Each response is assigned a score, which is then combined to provide you with an overall risk rating - there are five levels of risk ratings.',

    riskRatings: {
        title: 'What are the risk ratings?',
        listTitle: 'Click each level to learn more',
        radioGroup: [
            {
                levelNumber: '1',
                level: 'Lower',
                eventLabel: 'What are the risk ratings? > Lower',
                eventAction: 'Risk Analyser Questionnaire page',
            },
            {
                levelNumber: '2',
                level: 'Lower-Medium',
                eventLabel: 'What are the risk ratings? > Lower-Medium',
                eventAction: 'Risk Analyser Questionnaire page',
            },
            {
                levelNumber: '3',
                level: 'Medium',
                eventLabel: 'What are the risk ratings? > Medium',
                eventAction: 'Risk Analyser Questionnaire page',
            },
            {
                levelNumber: '4',
                level: 'Medium-Higher',
                eventLabel: 'What are the risk ratings? > Medium-Higher',
                eventAction: 'Risk Analyser Questionnaire page',
            },
            {
                levelNumber: '5',
                level: 'Higher',
                eventLabel: 'What are the risk ratings? > Higher',
                eventAction: 'Risk Analyser Questionnaire page',
            },
        ],
        riskGroup: [
            {
                level: 'Lower',
                content: `You're likely to be more conservative with your investments and understand that there may be some short-term changes in value to get potentially modest or relatively stable returns.`,
            },
            {
                level: 'Lower-Medium',
                content: `You're relatively cautious with your investments. You want the potential of getting reasonable long-term returns and are prepared to accept some risk in doing so. You understand there may be some frequent but small changes in value.`,
            },
            {
                level: 'Medium',
                content: `You have a balanced approach to risk. You don't look for risky investments, but you don't avoid them either. You're prepared to accept fluctuations in the value of your investments to try and get potentially better long-term returns. You understand that the value of your investments might change frequently and sometimes significantly.`,
            },
            {
                level: 'Medium-Higher',
                content: `You're comfortable taking some investment risk to get potentially better higher long-term returns, even if that means there might be times when you're getting potentially lower returns. You understand the value of your investments are likely to change frequently and often significantly.`,
            },
            {
                level: 'Higher',
                content: `You're very comfortable taking investment risk. You're aiming for potentially high long-term returns and are less concerned if the value of your investments go up and down over the short or medium term. You understand that the value of your investments is likely to change very frequently and significantly.`,
            },
        ],
        heading: 'How to assess your attitude to risk',
        content: 'This EV questionnaire can help you understand your attitude to risk but there are some limitations:',
        list: [
            {
                content: `The questionnaire is designed to help you decide for yourself and shouldn't be taken as advice`,
            },
            {
                content: `The questionnaire doesn't consider your age, your financial goals or how much money you'd be comfortable losing i.e. your capacity for loss`,
            },
            {
                content: `You should also consider how long you plan to invest for and when you think you might need access to your money`,
            },
        ],
    },
    attitudeRisk: {
        title: 'Take the questionnaire',
        content:
            'The EV attitude to risk questionnaire contains 13 multiple choice questions which you need to answer to discover your risk rating.',
        heading4: 'It should take less than 5 minutes.',
        alertHeading: `We don't store your data`,
        alertContent: `You don't need to tell us anything about yourself, just how you would react to or feel in certain
        scenarios. We won't store any of this information in a way that can be linked back to you.`,
        buttonText: `I'm ready to start`,
    },
};

export const resultsContent = {
    title: 'Your risk level is',
    buttonText: 'Retake the test',
    heading: 'Next Steps',
    sustainabilityResultText: 'Your attitude to sustainability',

    cards: [
        {
            id: 'downloadPDF',
            icon: IconDownload,
            iconAltText: 'download icon',
            title: 'Download the results and email to your adviser',
            content: `Save the report results to your computer and send as an attachment to your adviser's email address.`,
            buttonText: 'Download PDF',
        },
    ],
};
