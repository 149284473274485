import { useState } from 'react';
import { Col } from 'react-bootstrap';
import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react';

import { H2, H3, H4, H5, P } from '../ui/Typography';
import Button from '../ui/Button';
import RadioGroup from '../ui/RadioGroup';
import { useStartQuestionnaire } from './hooks';
import { Container, Row, Alert, Navigation, Gutter, Card } from './styles';
import { questionnaireStart } from 'data/content';
import { logAnalyticsEvent } from 'services/analytics';

function QuestionnaireStart() {
    const { startQuestionnaire } = useStartQuestionnaire();
    const [selectedRiskLevel, setSelectedRiskLevel] = useState('');

    return (
        <Container>
            <H2>{questionnaireStart.title}</H2>

            <P>{questionnaireStart.contentOne}</P>
            <P>{questionnaireStart.contentTwo}</P>
            <P>{questionnaireStart.contentThree}</P>
            <P>{questionnaireStart.contentFour}</P>

            <H3 className="mb-4">{questionnaireStart.riskRatings.title}</H3>
            <Card>
                <RadioGroup
                    title={questionnaireStart.riskRatings.listTitle}
                    name="risk-level"
                    value={selectedRiskLevel}
                    onChange={setSelectedRiskLevel}
                >
                    <div className="flex">
                        {questionnaireStart.riskRatings.radioGroup.map(riskLevel => (
                            /* Use the `active` state to conditionally style the active option. */
                            /* Use the `checked` state to conditionally style the checked option. */
                            <HeadlessRadioGroup.Option key={riskLevel.level} value={riskLevel.level}>
                                {({ checked }) => (
                                    <li
                                        onClick={() =>
                                            logAnalyticsEvent({
                                                action: riskLevel.eventAction,
                                                label: riskLevel.eventLabel,
                                            })
                                        }
                                        className={`${checked ? 'bg-blue' : ''}`}
                                    >
                                        <P className={`${checked ? 'text-white' : 'text-blue'}`}>
                                            {riskLevel.levelNumber}
                                        </P>
                                        <div className="hide">{riskLevel.level}</div>
                                    </li>
                                )}
                            </HeadlessRadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>

                {selectedRiskLevel && (
                    <div className="px-4">
                        {questionnaireStart.riskRatings.riskGroup.map(
                            riskLevel =>
                                selectedRiskLevel === riskLevel.level && (
                                    <div key={riskLevel.level}>
                                        <H5 className="mt-0">{riskLevel.level}</H5>
                                        <P>{riskLevel.content}</P>
                                    </div>
                                )
                        )}
                    </div>
                )}
            </Card>

            <Row>
                <Col>
                    <H3>{questionnaireStart.riskRatings.heading}</H3>
                    <P>{questionnaireStart.riskRatings.content}</P>
                    <Gutter />
                </Col>
            </Row>

            <ul>
                {questionnaireStart.riskRatings.list.map((listItem, index) => (
                    <li key={index}>{listItem.content}</li>
                ))}
            </ul>

            <H2>{questionnaireStart.attitudeRisk.title}</H2>
            <P>{questionnaireStart.attitudeRisk.content}</P>

            <H4>{questionnaireStart.attitudeRisk.heading4}</H4>

            <Alert title={questionnaireStart.attitudeRisk.alertHeading}>
                <P>{questionnaireStart.attitudeRisk.alertContent}</P>
            </Alert>

            <Navigation className="mb-5 text-center">
                <Button onClick={startQuestionnaire}>{questionnaireStart.attitudeRisk.buttonText}</Button>
            </Navigation>
        </Container>
    );
}

export default QuestionnaireStart;
